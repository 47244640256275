import React from "react";
import EntryBadgesV2 from "../general/badgesV2";
import License from "../general/licenses";
import { Img } from "react-image"
import Disclaimer from "../general/disclaimer";

const VirtualCardsHeader = (props) => {


    return (
        <div className="kuda-entry--fold">
            <div className="kuda-entry--inner flex justify-between align-center" >
                <div className="kuda-section--50">
                    <div className="kuda-main--wrap airtime-section--max">
                        <h1 className="text-xl kuda-main--heading text-xlbold color-primary title-bottom--spacing text-lg-biz">
                            {props.title}
                        </h1>
                        <p className="card-main--heading text-semi-bold color-black title-bottom--spacing card-main--heading-biz">
                            {props.subtitle}
                        </p>
                        <License  className="title-bottom--spacing"/>
                        <EntryBadgesV2/>
                    </div>
                </div>
                <div className="kuda-section--50">
                    <div className="kuda-illustration--center">
                        <Img
                            alt="kuda Microfinance Bank Savings, Spend, Budgeting."
                            src={props.illustration}
                            loader={props.fallback}
                        />
                    </div>
                </div>
            </div>
        </div>

    )
}

export default VirtualCardsHeader;
